@media all and (-ms-high-contrast: none) {
  /* IE 11 fixes go here */
  form {
    width: 100%;
  }
}

input[type="search"] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}
